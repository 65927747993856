<template>
    <div class="DeviceDetail">
        <el-card class="box-card">
            <i class="el-icon-close cha" @click="$router.go(-1)"></i>

            <div class="clearfix">
                基本信息
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">编号：</div>
                    <div class="info">{{ teamInfo.id }}</div>
                </div>
                <div class="item">
                    <div class="label">条码/标签：</div>
                    <div class="info">{{ teamInfo.barcode }}</div>
                </div>
                <div class="item">
                    <div class="label">工器具名称：</div>
                    <div class="info">{{ teamInfo.toolName }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">工器具分类：</div>
                    <div class="info">{{ teamInfo.className }}</div>
                </div>
                <div class="item">
                    <div class="label">工器具规格型号：</div>
                    <div class="info">{{ teamInfo.toolModel }}</div>
                </div>
                <div class="item">
                    <div class="label">电压等级:</div>
                    <div class="info">{{ teamInfo.voltageGrade }}</div>
                </div>
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">工器具编号：</div>
                    <div class="info">{{ teamInfo.toolNum }}</div>
                </div>
                <div class="item">
                    <div class="label">保质期/有效期（年）：</div>
                    <div class="info">{{ teamInfo.indate }}</div>
                </div>
                <div class="item">
                    <div class="label">出厂日期：</div>
                    <div class="info">{{ teamInfo.productionDate }}</div>
                </div>
            </div>

            <div class="df">
                <div class="item" style="font-weight: 700;">
                    <div class="label">状态：</div>
                    <div class="info">{{ teamInfo.status_text }}</div>
                </div>
                <div class="item">
                    <div class="label">存放位置：</div>
                    <div class="info">{{ teamInfo.whCodeName }}</div>
                </div>
                <div class="item">
                    <div class="label">存放位置：</div>
                    <div class="info">{{ teamInfo.location }}</div>
                </div>
                
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">配置标准：</div>
                    <div class="info">{{ teamInfo.deployNorm }}</div>
                </div>
                <div class="item">
                    <div class="label">现有配置：</div>
                    <div class="info">{{ teamInfo.nowDeploy }}</div>
                </div>
                <div class="item">
                    <div class="label">录入员账号：</div>
                    <div class="info">{{ teamInfo.account }}</div>
                </div>
               
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">录入时间：</div>
                    <div class="info">{{ teamInfo.recordTime }}</div>
                </div>
                <div class="item">
                    <div class="label">备注：</div>
                    <div class="info">{{ teamInfo.remark }}</div>
                </div>
            </div>
        </el-card>

        <el-card>
            <div class="clearfix">
                班组工器具试验信息
            </div>
            <div class="df">
                <div class="item">
                    <div class="label">上次试验日期：</div>
                    <div class="info">{{ teamInfo.lastTestDate }}</div>
                </div>
                <div class="item">
                    <div class="label">试验周期(月)：</div>
                    <div class="info">{{ teamInfo.testCycke }}</div>
                </div>
                <div class="item">
                    <div class="label">下次试验日期：</div>
                    <div class="info">{{ teamInfo.nextTestDate }}</div>
                </div>
            </div>
        </el-card>

        <el-card>
            <div class="clearfix">
                班组工器具详情图片
            </div>
            <div class="df-img">

                <div class="info" v-if="imgsList">
                    <div class="block" v-for="(item, index) in imgsList" :key="index">
                        <!-- <span class="demonstration">自定义</span> -->
                        <el-image :src="item" :preview-src-list="imgsList">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                        </el-image>
                    </div>
                </div>
            </div>
        </el-card>

    </div>
</template>

<script>

import { queryTeamToolInfo } from '@/api/teamLedger.js'
export default {
    props: {
        id: {
            type: [String, Number]
        }
    },
    data() {
        return {
            teamInfo: {
                barcode: '',
                toolName: '',
                classCode: '',
                className: '',
                toolModel: '',
                voltageGrade: '',
                toolNum: '',
                indate: '',
                productionDate: null,
                detailImg: '',
                location: null,
                lastTestDate: '',
                testCycke: '',
                nextTestDate: '',
                deployNorm: null,
                nowDeploy: '',
                status: '',
                param1: '',
                param2: '',
                remark: null,
                account: '',
                recordTime: ''
            },

            imgsList: [],
            path: process.env.VUE_APP_URL + this.UploadPath

        }
    },
    mounted() {
        console.log(this.id)
        this.id && this.loadDeviceInfo()
    },
    methods: {
        //根据id查询设备信息
        async loadDeviceInfo() {
            await queryTeamToolInfo(this.id).then(res => {
                if (res.code === '000000') {
                    this.teamInfo = res.t
                    switch (this.teamInfo.status) {
                        case '00':
                            this.teamInfo.status_text = '报废'
                            break;
                        case '01':
                            this.teamInfo.status_text = '正常'
                            break;
                        case '02':
                            this.teamInfo.status_text = '待试验'
                            break;
                        case '03':
                            this.teamInfo.status_text = '待试验告警'
                            break;
                        case '04':
                            this.teamInfo.status_text = '超周期不可用'
                            break;
                        default:
                            this.teamInfo.status_text = ''
                            break;
                    }
                    if (this.teamInfo.detailImg != null && this.teamInfo.detailImg != '') {
                        const list1 = this.teamInfo.detailImg.split(";")
                        list1.forEach(item => {
                            if (item != '' && item != null) {
                                const obj = this.path + item
                                this.imgsList.push(obj)
                            }
                        })
                    }
                }
            })
        },

    }
}

</script>


<style lang="scss" scoped>
.DeviceDetail {
    .el-card {
        position: relative;
        text-align: left;
        margin-bottom: 15px;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .clearfix {
        margin-bottom: 20px;
        font-size: 16px;
        color: #1d2129;
    }


    .el-icon-error {
        font-size: 20px;
    }

    .el-icon-error:hover {
        color: red;
    }

    .df:last-child {
        margin-bottom: 0;
    }

    .df {
        display: flex;
        margin-bottom: 16px;

        .item {
            width: 33.333333%;
            display: flex;
            font-size: 14px;
            color: #4e5969;

            .label {
                margin-right: 8px;
            }

            .info {
                // width: 100%;
                float: left;
            }
        }
    }

    .df-img {
        width: 100%;
        margin-bottom: 16px;

        .info {
            width: 100%;
            // float: left;
            display: flex;

            .block {
                width: 25%;
                margin-right: 10px;

                .el-image {
                    width: 100%;
                    height: 100%;
                }
            }

        }
    }


}
</style>